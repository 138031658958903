import { InvoiceItem } from './../../entities/invoice-item.model';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { InvoiceExtended } from 'src/app/entities/invoice.model';
import { User } from 'src/app/entities/user.model';
import { AlertService } from 'src/app/shared/components/alert/service/alert.service';
import { SharedService } from 'src/app/shared/services/shared.service';
import { OfficeService } from '../shared/service/office.service';
import { InvoiceClient } from 'src/app/entities/invoice-client.model';
import { CountryService } from 'src/app/shared/services/countries/country.service';
import { INVOICE_ACTIONS } from '../shared/models/invoice_actions.enum';
import { DownloadInvoiceService } from '../shared/service/download_invoice.service';
import { MonitoringService } from '../../shared/services/monitoring/monitoring.service';
import { formatAddress } from '../../shared/helpers/stripeAddress';
import { calculateTotalVAT } from '../../shared/helpers/tax.helper';
import { InvoiceTotalPipe } from '../shared/pipes/invoice-total.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { AutosizeModule } from 'ngx-autosize';
import { FormsModule } from '@angular/forms';
import {
  CurrencyPipe,
  DatePipe,
  DecimalPipe,
  NgClass,
  NgFor,
  NgIf,
  UpperCasePipe,
} from '@angular/common';
import { InvoiceGeneratingDialogComponent } from '../invoice-generating-dialog/invoice-generating-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-invoice-detail',
  templateUrl: './invoice-detail.component.html',
  styleUrls: ['./invoice-detail.component.scss'],
  imports: [
    NgIf,
    NgFor,
    FormsModule,
    AutosizeModule,
    NgClass,
    UpperCasePipe,
    DecimalPipe,
    CurrencyPipe,
    DatePipe,
    TranslateModule,
    InvoiceTotalPipe,
  ],
})
export class InvoiceDetailComponent implements OnInit {
  constructor(
    private sharedService: SharedService,
    private officeService: OfficeService,
    private activedRoute: ActivatedRoute,
    private router: Router,
    private alertService: AlertService,
    private countryService: CountryService,
    private downloadInvoiceService: DownloadInvoiceService,
    private dialog: MatDialog
  ) {}

  protected readonly calculateTotalVAT = calculateTotalVAT;

  public currentUser: User;
  public invoice: InvoiceExtended;
  public taxrates: Array<any>;
  public invoiceClient: InvoiceClient;
  balanceTransaction: Array<any>;

  public isSavingInvoice: boolean = false;
  public isSavingAndSendingInvoice: boolean = false;
  public isSendingEmailReminder: boolean = false;

  public countries: Array<any>;

  ngOnInit() {
    this.sharedService.currentUser.subscribe(
      (user) => (this.currentUser = user)
    );
    this.officeService.currentInvoice.subscribe((invoice) => {
      if (invoice) {
        this.setInvoiceClient();
        this.invoice = invoice;
        if (
          invoice.stripe &&
          invoice.stripe.charge &&
          invoice.stripe.charge.balance_transaction
        ) {
          // balance transaction from Stripe for "currency" and "net"
          this.balanceTransaction = invoice.stripe.charge.balance_transaction;
        }
        this.officeService
          .getClientDetails(this.invoice)
          .subscribe((client) => {
            if (client) {
              this.setInvoiceClient(client);
            }
          });
      }
    });
    this.officeService
      .getTaxrates()
      .subscribe((response) => (this.taxrates = response.data));
    this.countries = this.countryService.getCountries();
    this.loadInvoice();
  }

  ngOnDestroy() {
    this.officeService.setCurrentInvoice(null);
  }

  loadInvoice() {
    this.activedRoute.params.subscribe((params) => {
      if (params && params.id) {
        this.officeService.getCurrentInvoice(params.id);
      }
    });
  }

  saveChanges() {
    if (
      this.invoiceClient.address.line1 != '' &&
      !this.invoiceClient.address.country
    ) {
      this.alertService.error('Please fill out the whole client address');
      return;
    }
    if (this.isSavingInvoice) {
      return;
    }
    this.isSavingInvoice = true;
    this.invoice.invoiceClient = this.invoiceClient;
    new Promise((y, n) => {
      if (this.invoice.client) {
        this.officeService
          .updateClientDetails(this.invoice.client.id, this.invoiceClient)
          .subscribe((r) => {
            y(r);
          });
      } else {
        y(true);
      }
    }).then(() => {
      this.officeService.updateInvoice(this.invoice).subscribe((response) => {
        this.officeService.setCurrentInvoice(response.invoice);
        this.isSavingInvoice = false;
        this.alertService.success('Invoice Changes Saved');
      });
    });
  }

  removeInvoiceItem(item: InvoiceItem, index: number) {
    if (item.id) {
      this.officeService.removeInvoiceItem(item.id).subscribe((response) => {
        this.invoice.invoice_items.splice(index, 1);
        this.alertService.success('Removed Invoice Item');
      });
    } else {
      this.invoice.invoice_items.splice(index, 1);
      this.alertService.success('Removed Invoice Item');
    }
  }

  sendInvoice() {
    if (this.isSavingAndSendingInvoice) {
      return;
    }
    this.isSavingAndSendingInvoice = true;

    this.invoice.invoiceClient = this.invoiceClient;

    new Promise((y, n) => {
      if (this.invoice.client) {
        this.officeService
          .updateClientDetails(this.invoice.client.id, this.invoiceClient)
          .subscribe((response) => {
            y(true);
          });
      } else {
        y(true);
      }
    }).then(() => {
      this.officeService.updateInvoice(this.invoice).subscribe(() => {
        this.officeService.sendInvoice(this.invoice).subscribe((response) => {
          this.router.navigate(['office/invoices']);
          let rec = this.invoice.client
            ? this.invoice.client.name
            : this.invoice.client_email;
          let medium = this.invoice.client ? 'message' : 'email';
          this.alertService.success(`Invoice sent to ${rec} per ${medium}.`);
          this.isSavingAndSendingInvoice = false;
          this.invoice = response.invoice;
        });
      });
    });
  }

  totalTax() {
    // this is wrong
    let items = this.invoice ? this.invoice.invoice_items : [];
    return items.reduce(
      (a: any, b: InvoiceItem) => a + calculateTotalVAT(b.price, b.taxrate),
      0
    );
  }

  getInvoiceLink() {
    this.officeService.setCurrentInvoice(this.invoice);
    this.officeService.setCurrentInvoiceAction(INVOICE_ACTIONS.LINK);
  }

  deleteInvoice() {
    this.officeService.setCurrentInvoice(this.invoice);
    this.officeService.setCurrentInvoiceAction(INVOICE_ACTIONS.DELETE);
  }

  refundInvoice() {
    this.officeService.setCurrentInvoice(this.invoice);
    this.officeService.setCurrentInvoiceAction(INVOICE_ACTIONS.REFUND);
  }

  cancelInvoice() {
    this.officeService.setCurrentInvoice(this.invoice);
    this.officeService.setCurrentInvoiceAction(INVOICE_ACTIONS.CANCEL);
  }
  markInvoiceAsPaid() {
    this.officeService.setCurrentInvoice(this.invoice);
    this.officeService.setCurrentInvoiceAction(INVOICE_ACTIONS.MARK_AS_PAID);
  }

  addInvoiceItem() {
    var newInvoiceItem = new InvoiceItem();
    var defaultTaxRate = this.taxrates.filter(
      (tr) => tr.id == this.currentUser.profile.default_taxrate
    )[0];
    newInvoiceItem.taxrate_id = defaultTaxRate.id;
    newInvoiceItem.taxrate = defaultTaxRate.percentage;
    this.invoice.invoice_items.push(newInvoiceItem);
  }

  duplicateInvoice() {
    this.officeService.duplicateInvoice(this.invoice).subscribe((response) => {
      if (response.status == 'success') {
        this.router.navigate(['office', 'invoices', response.invoice.id]);
      }
    });
  }

  sendEmailReminder() {
    if (this.isSendingEmailReminder) {
      return;
    }
    this.isSendingEmailReminder = true;
    this.officeService.remindInvoice(this.invoice).subscribe((response) => {
      this.alertService.success('Sent Email Reminder');
      this.isSendingEmailReminder = false;
    });
  }

  onTaxrateChange(invoiceItem: InvoiceItem) {
    var taxrate = this.taxrates.filter(
      (tr) => tr.id == invoiceItem.taxrate_id
    )[0];
    invoiceItem.taxrate = taxrate.percentage;
  }

  setInvoiceClient(client?: InvoiceClient) {
    var _client = client || new InvoiceClient();
    if (!_client.address) {
      _client.address = {
        line1: null,
        line2: null,
        postal_code: null,
        city: null,
        state: null,
        country: null,
      };
    }
    this.invoiceClient = _client;
  }

  downloadInvoice() {
    if (!this.invoice || !this.currentUser || !this.invoiceClient) {
      MonitoringService.captureMessage("Can't download invoice, missing data");
      return;
    }
    const dialogRef = this.dialog.open(InvoiceGeneratingDialogComponent, {
      disableClose: true,
    });
    this.downloadInvoiceService
      .downloadInvoice(this.invoice, this.currentUser)
      .subscribe({
        next: () => dialogRef.close(),
        error: () => {
          dialogRef.close();
          // handle error (show a message to the user, etc.)
        },
      });
  }

  clientAddressLines() {
    return formatAddress(this.invoiceClient.address);
  }
}
