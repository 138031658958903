import { Component, Input, OnInit } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CommonModule } from '@angular/common';
import { SettingsService } from '../../../components/settings/service/settings.service';
import { User } from 'src/app/entities/user.model';
import { ProfileService } from '../../../components/profile/service/profile.service';
import { LauncherShareDialogComponent } from './launcher-share-dialog/launcher-share-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { SharedService } from '../../services/shared.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { InviteClientsService } from 'src/app/shared/components/invite-clients/service/invite-clients.service';

interface LauncherStep {
  id: string;
  hasCalendarCheck?: boolean;
  action: () => void;
}

@Component({
  selector: 'app-launcher',
  templateUrl: './launcher.component.html',
  styleUrls: ['./launcher.component.scss'],
  imports: [
    CommonModule,
    MatExpansionModule,
    MatIconModule,
    MatTooltipModule,
    TranslateModule,
  ],
})
export class LauncherComponent implements OnInit {
  @Input() currentUser: User;
  steps: LauncherStep[] = [];
  expandedStep: number | null = null;
  currentStepIndex: number = 0;
  private readonly BASE_PROGRESS_KEY = 'launcher-progress';

  constructor(
    private snackBar: MatSnackBar,
    private settingsService: SettingsService,
    private profileService: ProfileService,
    private dialog: MatDialog,
    private sharedService: SharedService,
    private translate: TranslateService,
    private inviteClientsService: InviteClientsService
  ) {}

  ngOnInit() {
    this.initializeSteps();
    this.initializeProgress();
  }

  private get progressKey(): string {
    return `${this.BASE_PROGRESS_KEY}-${this.currentUser?.id}`;
  }

  private initializeProgress(): void {
    if (!this.currentUser?.id) {
      console.warn('No user ID available for progress tracking');
      return;
    }

    if (
      this.currentUser?.profile?.calendar_enabled ||
      this.currentUser?.profile?.ignoreCalendar
    ) {
      const savedProgress = Number(
        localStorage.getItem(this.progressKey) || '1'
      );
      this.currentStepIndex = Math.min(
        Math.max(1, savedProgress),
        this.steps.length - 1
      );
    } else {
      this.currentStepIndex = 0;
    }

    this.expandedStep = this.currentStepIndex;
  }

  private initializeSteps(): void {
    this.steps = [
      {
        id: 'calendar',
        hasCalendarCheck: true,
        action: () =>
          (window.location.href = '/dashboard/home/settings/calendar'),
      },
      {
        id: 'social',
        action: () => this.copyProfileLink(),
      },
      {
        id: 'linkedin',
        action: () => this.openSharingModal(),
      },
      {
        id: 'community',
        action: () =>
          window.open('https://community.complicated.life/feed/', '_blank'),
      },
      {
        id: 'events',
        action: () =>
          window.open(
            'https://community.complicated.life/c/upcoming-events-itcomplicated/',
            '_blank'
          ),
      },
      {
        id: 'invitation',
        action: () => this.showInviteClientsModal(),
      },
    ];
  }

  isStepComplete(index: number): boolean {
    if (index === 0) {
      return (
        this.currentUser?.profile?.calendar_enabled ||
        this.currentUser?.profile?.ignoreCalendar ||
        this.currentStepIndex > 0
      );
    }
    return this.currentStepIndex > index;
  }

  isStepExpanded(index: number): boolean {
    return index === this.expandedStep;
  }

  isStepDisabled(index: number): boolean {
    return index > this.currentStepIndex;
  }

  onPanelOpened(index: number): void {
    if (!this.isStepDisabled(index)) {
      this.expandedStep = index;
    }
  }

  onPanelClosed(index: number): void {
    if (this.expandedStep === index) {
      this.expandedStep = null;
    }
  }

  markStepDone(index: number): void {
    if (index + 1 > this.currentStepIndex) {
      this.currentStepIndex = index + 1;
      localStorage.setItem(this.progressKey, this.currentStepIndex.toString());

      if (this.currentStepIndex === this.steps.length) {
        this.settingsService.markLauncherComplete().subscribe(
          (updatedUserResponse) => {
            this.sharedService.setCurrentUser(updatedUserResponse.user);
          },
          (error) => {
            console.error('Error marking launcher as completed', error);
          }
        );
        this.expandedStep = null;
      } else {
        this.expandedStep = this.currentStepIndex;
      }
    }
  }

  setIgnoreCalendar(): void {
    this.currentUser.profile.ignoreCalendar = true;
    this.profileService.updateItem(
      this.currentUser.profile,
      this.currentUser.profile.id
    );
    this.markStepDone(0);
  }

  copyProfileLink(): void {
    const profileLinkWithUTM = `${this.currentUser.profile.link}?utm_campaign=socials`;
    navigator.clipboard.writeText(profileLinkWithUTM);
    this.translate
      .get('launcher.share.copied_notification')
      .subscribe((msg: string) => {
        this.snackBar.open(
          msg,
          this.translate.instant('launcher.share.close'),
          {
            duration: 3000,
          }
        );
      });
  }

  openSharingModal(): void {
    const dialogRef = this.dialog.open(LauncherShareDialogComponent, {
      width: '600px',
      panelClass: 'share-dialog-container',
      data: { profileLink: this.currentUser.profile.link },
    });

    dialogRef.afterClosed().subscribe((result) => {
      // Optional: handle any post-dialog actions
    });
  }

  showInviteClientsModal() {
    this.inviteClientsService.openModal();
  }
}
