import { Component, OnInit } from '@angular/core';
import { OfficeService } from 'src/app/office/shared/service/office.service';
import { AlertService } from '../../alert/service/alert.service';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { LoadingAnimationComponent } from '../../loading-animation/loading-animation.component';
import {
  CurrencyPipe,
  KeyValuePipe,
  NgClass,
  NgFor,
  NgIf,
  UpperCasePipe,
} from '@angular/common';

@Component({
  selector: 'shared-office-payout-modal',
  templateUrl: './payout-modal.component.html',
  styleUrls: ['./payout-modal.component.css'],
  imports: [
    NgClass,
    NgIf,
    LoadingAnimationComponent,
    FormsModule,
    NgFor,
    UpperCasePipe,
    CurrencyPipe,
    KeyValuePipe,
    TranslateModule,
  ],
})
export class PayoutModalComponent implements OnInit {
  public stripeConnectAccount: any;
  public showModal: boolean;

  public bankaccounts: any[];
  public balances: any;

  public selected_payout: string = null;
  public destination: string = null;

  public isRequestingPayout: boolean = false;

  constructor(
    private officeService: OfficeService,
    private alertService: AlertService
  ) {}

  ngOnInit() {
    this.officeService.stripeConnectAccount.subscribe((account) => {
      this.stripeConnectAccount = account;
      if (this.stripeConnectAccount) {
        this.bankaccounts =
          this.stripeConnectAccount.external_accounts.data.filter(
            (a) => a.object == 'bank_account'
          );
        if (this.bankaccounts.length == 1) {
          this.destination = this.bankaccounts[0].id;
        }
      }
    });

    this.officeService.showPaymentModal.subscribe((state) => {
      this.showModal = state;
      if (this.showModal) {
        this.officeService.loadStripeConnectAccount();
        this.officeService.loadAccountBalance();
      }
    });

    this.officeService.accountBalances.subscribe((balances) => {
      this.balances = balances;
      if (this.balances) {
        var keys = Object.keys(this.balances);
        if (keys.length == 1) {
          this.selected_payout = keys[0];
        }
      }
    });
  }

  requestPayout() {
    this.isRequestingPayout = true;
    const cur = this.selected_payout;
    const amount = this.balances[cur].available;
    this.officeService
      .createPayout(amount, cur.toLowerCase(), this.destination)
      .subscribe((payout) => {
        this.officeService.loadAccountBalance();
        if (payout && payout.status == 'pending') {
          this.alertService.success('office.payout_modal.alerts.success');
        } else {
          this.alertService.error('office.payout_modal.alerts.error');
        }
        this.isRequestingPayout = false;
        this.closeModal();
      });
  }

  closeModal() {
    this.officeService.requestPayout(false);
  }
}
