import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TranslateModule } from '@ngx-translate/core';
import { LocalisedDatePipe } from '../../components/booking/booking-form-container/localised-date.pipe';
import { MatTableModule } from '@angular/material/table';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { OfficeService, PayoutDetails } from '../shared/service/office.service';
import { map, switchMap, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Invoice } from '../../entities/invoice.model';
import { InvoiceExportService } from '../shared/invoice_export_service';
import dayjs from 'dayjs';
import { getApplicationFee } from '../../shared/helpers/invoice.helper';
import { InvoiceGeneratingDialogComponent } from '../invoice-generating-dialog/invoice-generating-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { BulkInvoiceDownloadService } from '../shared/service/bulk_invoice_download.service';
import { SharedService } from '../../shared/services/shared.service';
import { User } from '../../entities/user.model';
import { AlertService } from '../../shared/components/alert/service/alert.service';

@Component({
  selector: 'app-payout-details',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatProgressSpinnerModule,
    TranslateModule,
    LocalisedDatePipe,
    MatTableModule,
    RouterModule,
  ],
  template: `
    <div class="content">
      <ng-container *ngIf="payout$ | async as payout">
        <h3>
          {{ 'office.payout.detail.payout_on' | translate }}
          {{ payout.arrival_date | localisedDate : 'L' }}
        </h3>

        <div class="card">
          <div class="card-content">
            <!-- Bank Information Box -->
            <div class="mb-5">
              <div class="is-flex is-align-items-center">
                <mat-icon class="mr-4 has-text-grey">account_balance</mat-icon>
                <div>
                  <div class="is-size-5 has-text-weight-medium">
                    {{ payout.destination?.bank_name }}
                  </div>
                  <div class="has-text-grey">
                    •••• {{ payout.destination?.last4 }}
                  </div>
                  <div
                    class="tag is-success is-light mt-2"
                    *ngIf="payout.method === 'instant'"
                  >
                    Instant-eligible
                  </div>
                </div>
              </div>
            </div>

            <!-- Details Grid -->
            <h4 class="subtitle mb-4">
              {{ 'office.payout.detail.overview' | translate }}
            </h4>
            <div class="overview-grid">
              <!-- Status -->
              <div class="detail-item">
                <div class="has-text-grey detail-label">
                  {{ 'office.payout.detail.status' | translate }}
                </div>
                <div class="detail-value">
                  <span class="tag" [ngClass]="getStatusClass(payout.status)">
                    {{ 'office.payout.statuses.' + payout.status | translate }}
                  </span>
                  <div
                    class="has-text-danger is-size-7 mt-2"
                    *ngIf="payout.failure_code"
                  >
                    {{ payout.failure_code }}
                  </div>
                </div>
              </div>

              <!-- Amount -->
              <div class="detail-item">
                <div class="has-text-grey detail-label">
                  {{ 'office.payout.detail.amount' | translate }}
                </div>
                <div class="detail-value">
                  {{
                    payout.amount / 100
                      | currency : (payout.currency | uppercase)
                  }}
                </div>
              </div>

              <!-- Type -->
              <div class="detail-item">
                <div class="has-text-grey detail-label">
                  {{ 'office.payout.detail.type' | translate }}
                </div>
                <div class="detail-value">
                  {{
                    (payout.automatic
                      ? 'office.payout.detail.automatic'
                      : 'office.payout.detail.manual'
                    ) | translate
                  }}
                </div>
              </div>
              <!-- Created -->
              <div class="detail-item">
                <div class="has-text-grey detail-label">
                  {{ 'office.payout.detail.date_created' | translate }}
                </div>
                <div class="detail-value">
                  {{ payout.created_at | localisedDate : 'L LT' }}
                </div>
              </div>

              <!-- Expected arrival -->
              <div class="detail-item">
                <div class="has-text-grey detail-label">
                  {{ 'office.payout.detail.arrival_date' | translate }}
                </div>
                <div class="detail-value">
                  {{ payout.arrival_date | localisedDate : 'L LT' }}
                </div>
              </div>

              <!-- Statement Descriptor -->
              <div class="detail-item" *ngIf="payout.statement_descriptor">
                <div class="has-text-grey detail-label">
                  {{ 'office.payout.detail.statement_descriptor' | translate }}
                </div>
                <div class="detail-value">
                  {{ payout.statement_descriptor }}
                </div>
              </div>

              <!-- Description -->
              <div class="detail-item" *ngIf="payout.description">
                <div class="has-text-grey detail-label">
                  {{ 'office.payout.detail.description' | translate }}
                </div>
                <div class="detail-value">{{ payout.description }}</div>
              </div>

              <!-- Trace ID -->
              <div class="detail-item" *ngIf="payout.trace_id_status">
                <div class="has-text-grey detail-label">
                  {{ 'office.payout.detail.trace_id' | translate }}
                </div>
                <div class="detail-value">
                  <ng-container [ngSwitch]="payout.trace_id_status">
                    <span *ngSwitchCase="'supported'">{{
                      payout.trace_id_value
                    }}</span>
                    <span
                      *ngSwitchDefault
                      class="tag"
                      [ngClass]="{
                        'is-warning': payout.trace_id_status === 'pending',
                        'is-light': payout.trace_id_status === 'unsupported'
                      }"
                    >
                      {{
                        'office.payout.trace_id_status.' +
                          payout.trace_id_status | translate
                      }}
                    </span>
                  </ng-container>
                </div>
              </div>

              <!-- Failure Code (already exists in Status box, but maybe we want it separate) -->
              <div class="detail-item" *ngIf="payout.failure_code">
                <div class="has-text-grey detail-label">
                  {{ 'office.payout.detail.failure_code' | translate }}
                </div>
                <div class="detail-value has-text-danger">
                  {{ payout.failure_code }}
                </div>
              </div>
            </div>

            <!-- Invoices Section -->
            <div class="mt-6" *ngIf="payout.invoices?.length">
              <div
                class="is-flex is-justify-content-space-between is-align-items-center mb-4"
              >
                <h4 class="subtitle mb-0">
                  {{ 'office.payout.detail.invoices' | translate }}
                </h4>
                <div
                  class="is-flex  is-align-items-center flex-gap-10 is-hidden-mobile"
                >
                  <button
                    mat-raised-button
                    color="primary"
                    (click)="exportInvoicesAsPDFs(payout)"
                  >
                    <mat-icon class="mr-2">download</mat-icon>
                    {{
                      'office.payout.detail.download_invoices_as_pdfs'
                        | translate
                    }}
                  </button>
                  <button
                    mat-raised-button
                    color="primary"
                    (click)="exportInvoicesAsCSVs(payout)"
                  >
                    <mat-icon class="mr-2">arrow_forward</mat-icon>
                    {{
                      'office.payout.detail.export_invoices_as_csv' | translate
                    }}
                  </button>
                </div>
              </div>

              <div class="table-container">
                <table class="table is-fullwidth">
                  <thead>
                    <tr>
                      <th>
                        {{ 'office.payout.detail.invoice_number' | translate }}
                      </th>
                      <th>{{ 'office.payout.detail.client' | translate }}</th>
                      <th class="is-hidden-mobile">
                        {{ 'office.payout.detail.total' | translate }}
                      </th>
                      <th class="is-hidden-mobile">
                        {{ 'office.payout.detail.fee' | translate }}
                      </th>
                      <th>
                        {{ 'office.payout.detail.net_amount' | translate }}
                      </th>
                      <th class="is-hidden-mobile has-text-right">
                        {{ 'office.payout.detail.view_invoice' | translate }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let invoice of sortInvoices(payout.invoices)">
                      <td>{{ invoice.stripe_invoice_number }}</td>
                      <td>
                        {{ invoice.client?.name || invoice.client_email }}
                      </td>
                      <td class="is-hidden-mobile">
                        {{
                          invoice.total
                            | currency : (payout.currency | uppercase)
                        }}
                      </td>
                      <td class="is-hidden-mobile">
                        {{
                          getApplicationFee(invoice)
                            | currency : (payout.currency | uppercase)
                        }}
                      </td>
                      <td>
                        {{
                          invoice.total - getApplicationFee(invoice)
                            | currency : (payout.currency | uppercase)
                        }}
                      </td>
                      <td class="is-hidden-mobile has-text-right">
                        <a
                          [routerLink]="['/office/invoices', invoice.id]"
                          mat-icon-button
                        >
                          <mat-icon>visibility</mat-icon>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <p class="has-text-grey is-size-7 mt-3">
                {{ 'office.payout.detail.disclaimer' | translate }}
              </p>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  `,
  styles: [
    `
      :host {
        display: block;
        width: 100%;
      }
      .has-border {
        border: 1px solid #dbdbdb;
        border-radius: 4px;
      }

      .tag.status-paid {
        background-color: #ebf9f0;
        color: #257942;
      }

      .tag.status-pending,
      .tag.status-in_transit {
        background-color: #f5f5f5;
        color: #4a4a4a;
      }

      .tag.status-failed {
        background-color: #feecf0;
        color: #cc0f35;
      }

      .tag.status-canceled {
        background-color: #f5f5f5;
        color: #7a7a7a;
      }

      .table-container {
        overflow-x: auto;
        max-width: 100%;
      }

      .tag.is-success.is-light {
        background-color: #ebf9f0;
        color: #257942;
      }
      .overview-grid {
        display: grid;
        gap: 1rem;
        grid-template-columns: repeat(3, 1fr);
      }

      .detail-item {
        border: 1px solid #dbdbdb;
        border-radius: 4px;
        padding: 1rem;
      }

      .detail-label {
        font-size: 0.875rem;
        margin-bottom: 0.5rem;
      }

      @media screen and (max-width: 768px) {
        .overview-grid {
          grid-template-columns: 1fr;
          gap: 0;
        }

        .detail-item {
          display: flex;
          justify-content: space-between;
          align-items: center;
          border: none;
          padding: 0.75rem 0;
          border-bottom: 1px solid #dbdbdb;
        }

        .detail-item:last-child {
          border-bottom: none;
        }

        .detail-label {
          margin-bottom: 0;
          margin-right: 1rem;
        }
      }
    `,
  ],
})
export class PayoutDetailsComponent implements OnInit, OnDestroy {
  payout$ = this.officeService.currentPayout;
  private destroy$ = new Subject<void>();
  private currentUser: User;
  protected readonly getApplicationFee = getApplicationFee;

  constructor(
    private route: ActivatedRoute,
    private officeService: OfficeService,
    private invoiceExportService: InvoiceExportService,
    private dialog: MatDialog,
    private bulkInvoiceDownloadService: BulkInvoiceDownloadService,
    private sharedService: SharedService,
    private alertService: AlertService
  ) {}

  ngOnInit() {
    this.route.params.pipe(takeUntil(this.destroy$)).subscribe((params) => {
      this.officeService.getCurrentPayout(params['id']);
    });
    this.sharedService.currentUser.subscribe((user) => {
      this.currentUser = user;
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    this.officeService.clearCurrentPayout();
  }

  getStatusClass(status: string): string {
    return `status-${status}`;
  }

  sortInvoices(invoices: Invoice[]) {
    return (
      invoices?.sort((a, b) =>
        a.stripe_invoice_number.localeCompare(b.stripe_invoice_number)
      ) || []
    );
  }

  exportInvoicesAsCSVs(payout: PayoutDetails) {
    if (payout.invoices?.length) {
      this.invoiceExportService.exportToCsv(
        payout.invoices,
        `payout_${payout.id}_invoices`,
        payout.stripe_id
      );
    }
  }

  exportInvoicesAsPDFs(payout: PayoutDetails) {
    if (payout.invoices?.length && this.currentUser) {
      const dialogRef = this.dialog.open(InvoiceGeneratingDialogComponent, {
        disableClose: true,
        data: { total: payout.invoices.length },
      });

      this.bulkInvoiceDownloadService
        .downloadInvoicesAsZip(payout.invoices, this.currentUser)
        .subscribe({
          next: (progress) => {
            dialogRef.componentInstance.current = progress;
          },
          error: () => {
            this.alertService.error('office.invoice.actions.download_error');
            dialogRef.close();
          },
          complete: () => {
            dialogRef.close();
          },
        });
    }
  }
}
