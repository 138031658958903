import { Invoice } from '../../entities/invoice.model';

export function getApplicationFee(invoice: Invoice): number {
  const applicationFee = invoice.fee_rate
    ? parseFloat((invoice.total * invoice.fee_rate).toFixed(2))
    : parseFloat((invoice.application_fee / 100).toFixed(2));
  // Stripe fee is only relevant in old invoices, where it is not included in the application fee (fee_rate is null)
  const stripeFee = invoice.fee_rate ? 0 : invoice.stripe_fee / 100;
  return applicationFee + stripeFee;
}
