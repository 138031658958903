import { defaults } from './environment-defaults';

export const environment = {
  ...defaults,
  version: 'VERSION',
  production: true,
  sentry: true,
  sentryRelease: '27b584df3689f7c2588ff23419b75bf7da89c77c', // will be replaced with the actual release by the CI/CD pipeline
  name: 'production',
  domain: 'complicated.life',
  domain_name: 'complicated.life',
  baseUrl: 'https://complicated.life',
  railsBaseUrl: 'https://api.complicated.life',
  apiBase: 'https://api.complicated.life/api',
  appUrl: 'https://complicated.life/dashboard',
  resetPassword: 'https://complicated.life/reset-password',
  ws: 'wss://api.complicated.life/cable',
  vapid:
    'BGMBZoTzK_pLpamvFbjhJX-sGWV_BfMmq-17jo_bAHjARiT3H8Qi2DUOe7BZkjBIchiNIzZfm8b9D7bRgkC7rCg',
  aws: 'https://s3.eu-central-1.amazonaws.com/its-complicated/',
  stripe: 'pk_live_BnFdxpksIEZxfCp0QTMx6KAq',
  tokbox: 'adf489a2-9241-45ac-a8b5-58cf4b21a411',
  // Any change must be applied to the Amplitude instance in the Rails app too
  amplitudeApiKey: '8f7f4f717a8b80e38c3d37f01bb5b78c',
  // Any change must be applied to the rails credentials file
  googleClientId:
    '41044786873-mk7ndl5mqg8hnp20d5ufh70qvq6aqh8m.apps.googleusercontent.com',
  featureTestGroup: [11, 31, 381, 1221, 41001, 5351],
  isAuth0Enabled: true,
  auth0: {
    domain: 'login.complicated.life',
    clientId: 'VRmb1yQnfXOPDcqsKOCAChsORWW9DxU7',
    audience: 'https://complicated.life/api',
    default_connection: 'IC-Production',
  },
  isAuthLoggingEnabled: false,
  isNotificationLoggingEnabled: false,
  areBrowserNotificationsEnabled: false,
  baseHref: '/dashboard/',
  getstreamApiKey: 'bb6htvccet7t',
  supportUserId: 5381,
};
