import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { Invoice, InvoiceExtended } from 'src/app/entities/invoice.model';
import { User } from 'src/app/entities/user.model';
import { AlertService } from 'src/app/shared/components/alert/service/alert.service';
import { SharedService } from 'src/app/shared/services/shared.service';
import { INVOICE_ACTIONS } from '../shared/models/invoice_actions.enum';
import { OfficeService } from '../shared/service/office.service';
import { DownloadInvoiceService } from '../shared/service/download_invoice.service';
import { MonitoringService } from '../../shared/services/monitoring/monitoring.service';
import { MatSort, MatSortHeader } from '@angular/material/sort';
import {
  MatCell,
  MatColumnDef,
  MatHeaderCell,
  MatHeaderRow,
  MatRow,
  MatTable,
  MatTableDataSource,
  MatTableModule,
} from '@angular/material/table';
import { TranslateModule } from '@ngx-translate/core';
import { MatIcon } from '@angular/material/icon';
import { MatMenu, MatMenuItem, MatMenuTrigger } from '@angular/material/menu';
import { MatIconButton } from '@angular/material/button';
import { CurrencyPipe, DatePipe, NgIf, UpperCasePipe } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { InvoiceGeneratingDialogComponent } from '../invoice-generating-dialog/invoice-generating-dialog.component';

@Component({
  selector: 'office-invoice-table',
  templateUrl: './office-invoice-table.component.html',
  styleUrls: ['./office-invoice-table.component.scss'],
  imports: [
    MatTable,
    MatSort,
    MatColumnDef,
    MatHeaderCell,
    MatSortHeader,
    MatCell,
    NgIf,
    MatIconButton,
    MatMenuTrigger,
    MatIcon,
    MatMenu,
    MatMenuItem,
    MatTableModule,
    RouterLink,
    MatHeaderRow,
    MatRow,
    UpperCasePipe,
    CurrencyPipe,
    DatePipe,
    TranslateModule,
  ],
})
export class OfficeInvoiceTableComponent implements OnInit {
  @Input()
  showHeader: boolean = true;
  @Input()
  showFooter: boolean = true;
  dataSource: MatTableDataSource<Invoice>;

  @Input()
  set invoices(invoices: Invoice[]) {
    if (invoices) {
      this.dataSource = new MatTableDataSource(invoices);
      this.dataSource.sortingDataAccessor = (item, property) => {
        switch (property) {
          case 'created_at':
            return new Date(item.created_at);
          case 'amount':
            return item.total;
          case 'invoice_no':
            return item.stripe_invoice_number;
          case 'client':
            if (item.client) {
              return item.client.name;
            } else {
              return item.client_email;
            }
          default:
            return item[property];
        }
      };

      this._invoiceTotal =
        invoices.length > 0
          ? invoices.map((i) => i.total).reduce((curr, acc) => curr + acc)
          : 0;
    }
  }

  @ViewChild(MatSort) set matSort(sort: MatSort) {
    // we do this in a setter to make sure it happens when the table is rendered
    // https://technology.amis.nl/frontend/sorting-an-angular-material-table/
    this.dataSource.sort = sort;
  }

  _invoiceTotal: number;

  currentUser: User;

  displayedColumns = [
    'created_at',
    'invoice_no',
    'client',
    'status',
    'amount',
    'actions',
  ];

  constructor(
    private sharedService: SharedService,
    private alertService: AlertService,
    private officeService: OfficeService,
    private downloadInvoiceService: DownloadInvoiceService,
    private router: Router,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.sharedService.currentUser.subscribe(
      (user) => (this.currentUser = user)
    );
  }

  downloadInvoice(e: Event, invoice: InvoiceExtended) {
    e.stopPropagation();
    this.officeService
      .getInvoice(invoice.id)
      .subscribe((invoiceWithStripeExtras) => {
        if (invoiceWithStripeExtras) {
          const dialogRef = this.dialog.open(InvoiceGeneratingDialogComponent, {
            disableClose: true,
          });
          this.downloadInvoiceService
            .downloadInvoice(invoiceWithStripeExtras, this.currentUser)
            .subscribe({
              next: () => dialogRef.close(),
              error: () => {
                dialogRef.close();
                // handle error (show a message to the user, etc.)
              },
            });
        } else {
          MonitoringService.captureMessage('Error downloading invoice', {
            extra: { invoiceId: invoice.id },
          });
          this.alertService.error('office.invoice.actions.download_error');
        }
      });
  }

  duplicateInvoice(e: Event, invoice: InvoiceExtended) {
    e.stopPropagation();
    this.officeService.duplicateInvoice(invoice).subscribe((response) => {
      if (response.status === 'success') {
        this.router.navigate(['office', 'invoices', response.invoice.id]);
      }
    });
  }

  getInvoiceLink(e: Event, invoice: InvoiceExtended) {
    e.stopPropagation();
    this.officeService.setCurrentInvoice(invoice);
    this.officeService.setCurrentInvoiceAction(INVOICE_ACTIONS.LINK);
  }

  deleteInvoice(e: Event, invoice: InvoiceExtended) {
    e.stopPropagation();
    this.officeService.setCurrentInvoice(invoice);
    this.officeService.setCurrentInvoiceAction(INVOICE_ACTIONS.DELETE);
  }

  refundInvoice(e: Event, invoice: InvoiceExtended) {
    e.stopPropagation();
    this.officeService.setCurrentInvoice(invoice);
    this.officeService.setCurrentInvoiceAction(INVOICE_ACTIONS.REFUND);
  }

  cancelInvoice(e: Event, invoice: InvoiceExtended) {
    e.stopPropagation();
    this.officeService.setCurrentInvoice(invoice);
    this.officeService.setCurrentInvoiceAction(INVOICE_ACTIONS.CANCEL);
  }

  markInvoiceAsPaid(e: Event, invoice: InvoiceExtended) {
    e.stopPropagation();
    this.officeService.setCurrentInvoice(invoice);
    this.officeService.setCurrentInvoiceAction(INVOICE_ACTIONS.MARK_AS_PAID);
  }

  sendEmailReminder(e: Event, invoice: InvoiceExtended) {
    this.officeService.remindInvoice(invoice).subscribe((response) => {
      this.alertService.success('Sent Email Reminder');
    });
  }

  onRowClick(row) {
    this.router.navigate(['office', 'invoices', row.id]);
  }
}
