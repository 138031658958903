import {
  AfterViewInit,
  Component,
  Injector,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { User } from 'src/app/entities/user.model';
import { SharedService } from 'src/app/shared/services/shared.service';
import { OfficeService } from '../shared/service/office.service';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { CurrencyPipe, NgForOf, NgIf, UpperCasePipe } from '@angular/common';
import { AlertService } from '../../shared/components/alert/service/alert.service';
import { debounceTime, finalize, from, Subject, Subscription } from 'rxjs';
import { distinctUntilChanged, mergeMap } from 'rxjs/operators';
import {
  MatFormField,
  MatFormFieldModule,
  MatPrefix,
} from '@angular/material/form-field';
import { MatOption, MatSelect } from '@angular/material/select';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { MatPaginator } from '@angular/material/paginator';
import { MatMenu, MatMenuItem, MatMenuTrigger } from '@angular/material/menu';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatSort, MatSortHeader, MatSortModule } from '@angular/material/sort';
import { Invoice, InvoiceExtended } from 'src/app/entities/invoice.model';
import { SelectionModel } from '@angular/cdk/collections';
import { Router, RouterLink } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { BulkInvoiceDownloadService } from '../shared/service/bulk_invoice_download.service';
import { DownloadInvoiceService } from '../shared/service/download_invoice.service';
import { InvoiceGeneratingDialogComponent } from '../invoice-generating-dialog/invoice-generating-dialog.component';
import { MonitoringService } from '../../shared/services/monitoring/monitoring.service';
import { INVOICE_ACTIONS } from '../shared/models/invoice_actions.enum';
import {
  MatDatepickerModule,
  MatDatepickerToggle,
  MatDateRangeInput,
  MatDateRangePicker,
  MatEndDate,
  MatStartDate,
} from '@angular/material/datepicker';
import { MatButton, MatIconButton } from '@angular/material/button';
import { MatNativeDateModule } from '@angular/material/core';
import { LocalisedDatePipe } from '../../components/booking/booking-form-container/localised-date.pipe';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { DateHelpers } from '../../shared/helpers/date_helpers';
import { InvoiceExportDialogComponent } from '../invoice-export-dialog/invoice-export-dialog.component';

dayjs.extend(localizedFormat);

@Component({
  selector: 'office-invoice-list',
  templateUrl: './invoice-list.component.html',
  styleUrls: ['./invoice-list.component.scss'],
  providers: [],
  imports: [
    NgIf,
    FormsModule,
    TranslateModule,
    NgForOf,
    MatFormField,
    MatSelect,
    MatOption,
    MatIcon,
    MatInput,
    MatPrefix,
    MatPaginator,
    MatMenu,
    MatCheckbox,
    MatProgressSpinner,
    MatTableModule,
    MatDateRangeInput,
    MatStartDate,
    MatEndDate,
    MatDatepickerToggle,
    MatDateRangePicker,
    CurrencyPipe,
    UpperCasePipe,
    MatMenuTrigger,
    MatIconButton,
    MatMenuItem,
    MatButton,
    RouterLink,
    MatSortHeader,
    MatSort,
    MatFormFieldModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSortModule,
    LocalisedDatePipe,
  ],
})
export class InvoiceListComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  dataSource: MatTableDataSource<Invoice>;
  currentUser: User;
  isLoading = false;
  totalItems = 0;
  pageSize = 25;
  currentPage = 1;
  filterQuery = '';
  selectedStatus = '';
  datePlaceholder = '';

  dateFilters = {
    finalized: { start: null, end: null },
    paid: { start: null, end: null },
  };

  private searchSubject = new Subject<string>();
  private searchSubscription: Subscription;

  displayedColumns = [
    'select',
    'invoice_no',
    'client',
    'status',
    'amount',
    'finalized_at',
    'paid_at',
    'actions',
  ];

  statusOptions = [
    { value: '', label: 'office.invoice.list.all_statuses' },
    { value: 'draft', label: 'office.invoice.statuses.draft' },
    { value: 'open', label: 'office.invoice.statuses.open' },
    { value: 'paid', label: 'office.invoice.statuses.paid' },
    { value: 'canceled', label: 'office.invoice.statuses.canceled' },
    { value: 'refunded', label: 'office.invoice.statuses.refunded' },
    {
      value: 'paid_externally',
      label: 'office.invoice.statuses.paid_externally',
    },
    {
      value: 'payment_processing',
      label: 'office.invoice.statuses.payment_processing',
    },
  ];

  selectedInvoices = new Map<number, Invoice>();
  selection: SelectionModel<Invoice>;

  validDateFilter = (d: Date | null): boolean => {
    // Return false if it's not a valid date object
    return d instanceof Date && !isNaN(d.getTime());
  };

  constructor(
    private officeService: OfficeService,
    private sharedService: SharedService,
    private alertService: AlertService,
    private router: Router,
    private dialog: MatDialog,
    private bulkDownloadService: BulkInvoiceDownloadService,
    private downloadInvoiceService: DownloadInvoiceService,
    private injector: Injector
  ) {
    this.selection = new SelectionModel<Invoice>(
      true,
      [],
      false,
      (a: InvoiceExtended, b: InvoiceExtended) => a.id === b.id
    );
  }

  ngOnInit() {
    this.sharedService.currentUser.subscribe(
      (user) => (this.currentUser = user)
    );

    this.searchSubscription = this.searchSubject
      .pipe(debounceTime(300), distinctUntilChanged())
      .subscribe(() => this.loadInvoices());

    this.loadInvoices();
    this.datePlaceholder = dayjs()
      .localeData()
      .longDateFormat('L')
      .toLowerCase();
  }

  ngAfterViewInit() {
    // Initialize sorting
    if (this.sort) {
      this.sort.sortChange.subscribe(() => {
        if (this.paginator) {
          this.paginator.firstPage();
        }
        this.loadInvoices();
      });
    }

    // Initialize pagination
    if (this.paginator) {
      this.paginator.page.subscribe(() => {
        this.loadInvoices();
      });
    }

    // Ensure initial sort is applied
    if (this.dataSource) {
      this.dataSource.sort = this.sort;
    }
  }

  ngOnDestroy() {
    if (this.searchSubscription) {
      this.searchSubscription.unsubscribe();
    }
  }

  onSearchChange(query: string) {
    this.filterQuery = query;
    if (this.paginator) {
      this.paginator.firstPage();
    }
    this.clearSelections(); // Clear selections on filter change
    this.searchSubject.next(query);
  }

  onStatusChange() {
    if (this.paginator) {
      this.paginator.firstPage();
    }
    this.clearSelections(); // Clear selections on status change
    this.loadInvoices();
  }

  onDateFilterChange() {
    if (this.paginator) {
      this.paginator.firstPage();
    }
    this.clearSelections(); // Clear selections on date filter change
    this.loadInvoices();
  }

  loadInvoices() {
    this.isLoading = true;

    const params = {
      page: this.paginator?.pageIndex + 1 || 1,
      per_page: this.paginator?.pageSize || 25,
      sort: this.sort?.active || 'created_at',
      direction: this.sort?.direction || 'desc',
      query: this.filterQuery,
      status: this.selectedStatus,
      ...(this.validDateFilter(this.dateFilters.finalized.start) && {
        finalized_start: DateHelpers.formatStartOfDayUTC(
          this.dateFilters.finalized.start
        ),
      }),
      ...(this.validDateFilter(this.dateFilters.finalized.end) && {
        finalized_end: DateHelpers.formatEndOfDayUTC(
          this.dateFilters.finalized.end
        ),
      }),
      ...(this.validDateFilter(this.dateFilters.paid.start) && {
        paid_start: DateHelpers.formatStartOfDayUTC(
          this.dateFilters.paid.start
        ),
      }),
      ...(this.validDateFilter(this.dateFilters.paid.end) && {
        paid_end: DateHelpers.formatEndOfDayUTC(this.dateFilters.paid.end),
      }),
    };

    this.officeService.getPaginatedInvoices(params).subscribe({
      next: (response) => {
        this.dataSource = new MatTableDataSource(response.invoices);
        this.totalItems = response.total_count;

        // Restore selections for current page
        this.dataSource.data.forEach((invoice) => {
          if (this.selectedInvoices.has(invoice.id)) {
            this.selection.select(invoice);
          }
        });

        this.isLoading = false;
      },
      error: (error) => {
        console.error('Error loading invoices:', error);
        this.isLoading = false;
        this.alertService.error('Error loading invoices');
      },
    });
  }

  get isFinalizedAtDateFilterActive(): boolean {
    return !!(
      this.dateFilters.finalized.start || this.dateFilters.finalized.end
    );
  }

  get isPaidDateFilterActive(): boolean {
    return !!(this.dateFilters.paid.start || this.dateFilters.paid.end);
  }

  clearFinalizedDateFilter(): void {
    this.dateFilters.finalized.start = null;
    this.dateFilters.finalized.end = null;
    this.onDateFilterChange();
  }

  clearPaidDateFilter(): void {
    this.dateFilters.paid.start = null;
    this.dateFilters.paid.end = null;
    this.onDateFilterChange();
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource?.data.length || 0;
    return numSelected === numRows && numRows > 0;
  }

  masterToggle() {
    if (this.isAllSelected()) {
      // Deselect only items on current page
      this.dataSource.data.forEach((row) => {
        this.selection.deselect(row);
        this.selectedInvoices.delete(row.id);
      });
    } else {
      // Select all items on current page
      this.dataSource.data.forEach((row) => {
        this.selection.select(row);
        this.selectedInvoices.set(row.id, row);
      });
    }
  }

  // Modified selection toggle to maintain the selection map
  toggleSelection(row: InvoiceExtended) {
    this.selection.toggle(row);
    if (this.selection.isSelected(row)) {
      this.selectedInvoices.set(row.id, row);
    } else {
      this.selectedInvoices.delete(row.id);
    }
  }

  // Clear all selections
  clearSelections() {
    this.selection.clear();
    this.selectedInvoices.clear();
  }

  downloadSelected() {
    const selectedInvoices = Array.from(this.selectedInvoices.values());
    const dialogRef = this.dialog.open(InvoiceGeneratingDialogComponent, {
      disableClose: true,
      data: { total: selectedInvoices.length },
    });

    this.bulkDownloadService
      .downloadInvoicesAsZip(selectedInvoices, this.currentUser)
      .subscribe({
        next: (progress) => {
          dialogRef.componentInstance.current = progress;
        },
        error: () => {
          this.alertService.error('office.invoice.actions.download_error');
          dialogRef.close();
        },
        complete: () => {
          dialogRef.close();
          this.clearSelections();
        },
      });
  }

  downloadInvoice(e: Event, invoice: InvoiceExtended) {
    e.stopPropagation();
    this.officeService
      .getInvoice(invoice.id)
      .subscribe((invoiceWithStripeExtras) => {
        if (invoiceWithStripeExtras) {
          const dialogRef = this.dialog.open(InvoiceGeneratingDialogComponent, {
            disableClose: true,
          });
          this.downloadInvoiceService
            .downloadInvoice(invoiceWithStripeExtras, this.currentUser)
            .subscribe({
              next: () => dialogRef.close(),
              error: () => {
                dialogRef.close();
                this.alertService.error(
                  'office.invoice.actions.download_error'
                );
              },
            });
        } else {
          MonitoringService.captureMessage('Error downloading invoice', {
            extra: { invoiceId: invoice.id },
          });
          this.alertService.error('office.invoice.actions.download_error');
        }
      });
  }

  duplicateInvoice(e: Event, invoice: InvoiceExtended) {
    e.stopPropagation();
    this.officeService.duplicateInvoice(invoice).subscribe((response) => {
      if (response.status === 'success') {
        this.router.navigate(['office', 'invoices', response.invoice.id]);
      }
    });
  }

  getInvoiceLink(e: Event, invoice: InvoiceExtended) {
    e.stopPropagation();
    this.officeService.setCurrentInvoice(invoice);
    this.officeService.setCurrentInvoiceAction(INVOICE_ACTIONS.LINK);
  }

  deleteInvoice(e: Event, invoice: InvoiceExtended) {
    e.stopPropagation();
    this.officeService.setCurrentInvoice(invoice);
    this.officeService.setCurrentInvoiceAction(INVOICE_ACTIONS.DELETE);
  }

  refundInvoice(e: Event, invoice: InvoiceExtended) {
    e.stopPropagation();
    this.officeService.setCurrentInvoice(invoice);
    this.officeService.setCurrentInvoiceAction(INVOICE_ACTIONS.REFUND);
  }

  cancelInvoice(e: Event, invoice: InvoiceExtended) {
    e.stopPropagation();
    this.officeService.setCurrentInvoice(invoice);
    this.officeService.setCurrentInvoiceAction(INVOICE_ACTIONS.CANCEL);
  }

  markInvoiceAsPaid(e: Event, invoice: InvoiceExtended) {
    e.stopPropagation();
    this.officeService.setCurrentInvoice(invoice);
    this.officeService.setCurrentInvoiceAction(INVOICE_ACTIONS.MARK_AS_PAID);
  }

  sendEmailReminder(e: Event, invoice: InvoiceExtended) {
    if (invoice.status === 'open') {
      this.officeService.remindInvoice(invoice).subscribe((response) => {
        this.alertService.success('office.invoice.actions.sent_email_reminder');
      });
    }
  }

  onRowClick(row) {
    this.router.navigate(['office', 'invoices', row.id]);
  }

  hasOpenInvoices(): boolean {
    return Array.from(this.selectedInvoices.values()).some(
      (invoice) => invoice.status === 'open'
    );
  }

  getOpenInvoicesCount(): number {
    return Array.from(this.selectedInvoices.values()).filter(
      (invoice) => invoice.status === 'open'
    ).length;
  }

  sendBulkReminders() {
    // First filter to only get open invoices
    const openInvoices = this.selection.selected.filter(
      (invoice) => invoice.status === 'open'
    );

    // Only proceed if there are open invoices
    if (openInvoices.length > 0) {
      from(openInvoices)
        .pipe(
          mergeMap((invoice) => this.officeService.remindInvoice(invoice)),
          finalize(() => this.selection.clear())
        )
        .subscribe({
          complete: () => {
            this.alertService.success(
              'office.invoice.actions.sent_bulk_reminders'
            );
          },
          error: () => {
            this.alertService.error('office.invoice.actions.reminder_error');
          },
        });
    }
  }

  openExportDialog() {
    this.dialog.open(InvoiceExportDialogComponent, {
      width: '500px',
      data: {
        status: this.selectedStatus,
        query: this.filterQuery,
        dateFilters: this.dateFilters,
      },
      injector: this.injector,
    });
  }
}
